<template>
  <div>
    <AppSubHeader link="tdrd" title="Mi Tarjeta de Rebancarización" />

    <div
      class="container align-items-center card p-3 mt-3 border border-white rounded-lg bg-light"
    >
      <div class="row d-flex">
        <h4 class="text-muted p-2">TARJETA DE REBANCARIZACIÓN</h4>
      </div>
      <div class="row">
        <template v-if="!datosCargados">
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="!errorCargaDatos && !pendingActivation"
          >
          </v-progress-circular>
          <p v-if="pendingActivation" class="ml-5">
            Su tarjeta se encuentra en estado de activación
          </p>
          <p v-else class="ml-5">
            Porfavor espere mientras se carga la información
          </p>
        </template>
        <template v-else>
          <div
            class="col-sm-12 col-lg-5 d-flex justify-content-center align-items-center flex-column"
          >
            <template v-if="isDigital">
              <div class="tarjeta">
                <TdrSvgVue
                  :cardNumber="numberTD"
                  :name="fullName"
                  :goodThru="fechaExpiracionTC"
                />
              </div>
              <div
                class="col d-flex justify-content-center align-items-center"
                v-if="TD_Autenticated"
              >
                <!-- <button class="btn btn-outline-info">
              Tu CVV ha sido enviado a tu celular
            </button> -->
                <v-alert text dense color="teal" type="success" border="top">
                  El CVV ha sido enviado a su celular
                </v-alert>
              </div>
              <div
                class="col d-flex justify-content-center align-items-center p-2"
                v-else-if="!conSaldoMora && !bloquedCreditCard"
              >
                <button class="btn btn-info btn-sm" @click="abrirModal">
                  Ver información de tarjeta
                </button>
              </div>
            </template>
            <template v-else>
              <div class="tarjeta">
                <TdrSvgVue :name="fullName" />
              </div>
            </template>
            <template v-if="tarjeta.bloqueada">
              <v-alert text dense prominent type="error" border="top">
                Bloqueada
              </v-alert>
            </template>
          </div>

          <!-- columna card info -->
          <div class="col-sm-12 col-lg-7 my-sm-3 bg-light p-2">
            <div>
              <InfoTarjetaCard></InfoTarjetaCard>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Seccion de paneles de transacciones y banner -->
    <div class="container align-items-center p-3 mt-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 rounded-1 foot my-auto">
          <div class="banner">
            <img
              src="../../public/assets/theme/app/media/img/bannersTDR/Banner 2.png"
              alt="banner con señora mostrando tarjeta virtual y un mensaje de la importancia de un buen historial crediticio"
              class="img-fluid rounded-lg"
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 mt-md-0 transactions rounded-1 foot">
          <TransactionsWidget :transactions="transactions"></TransactionsWidget>
        </div>
      </div>
    </div>
    <ModalTarjetaCredito :openModal="openModal" v-if="openModal" />
    <template>
      <div>
        <!-- Otras partes de tu componente aquí -->
         <template v-if="!pendingActivation">
          <v-snackbar
          v-model="errorCargaDatos"
          color="error"
          top
          multi-line
          timeout="5000"
        >
          Ha ocurrido un error al cargar los datos.
        </v-snackbar>

         </template>
        <v-snackbar
          v-model="errorValidationTC"
          color="light-blue darken-2"
          centered
          multi-line
          timeout="5000"
        >
          No existen datos disponibles
        </v-snackbar>
        <v-dialog
      v-model="dialogPendingActivation"
      transition="dialog-bottom-transition"
      max-width="400"
    >
      <v-card>
        <v-alert
        text
        dense
        color="teal"
        icon="mdi-clock-fast"
        border="left"
        class="w-100"
    >
      <v-row align="center">
        <v-col class="grow">
          Su tarjeta se encuentra en estado de activación
        </v-col>
        <v-col class="shrink">
          <v-btn
        @click="dialogPendingActivation=false"
          color="info"
          outlined
          >
          Cerrar
        </v-btn>
        </v-col>
      </v-row>
    </v-alert>
      </v-card>
    </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import AppSubHeader from "@/components/layout/AppSubHeader.vue";
import PaymentButton from "@/components/ui/PaymentButton.vue";
import TdrSvgVue from "@/views/TdrSvg.vue";
import ModalTarjetaCredito from "@/views/tarjetaVirtual/ModalTarjetaCredito.vue";
import TransactionsWidget from "@/views/TransactionsWidget.vue";
import InfoTarjetaCard from "@/views/InfoTarjetaCard.vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "DigitalCreditCard",
  components: {
    AppSubHeader,
    PaymentButton,
    TdrSvgVue,
    TransactionsWidget,
    ModalTarjetaCredito,
    InfoTarjetaCard
  },
  props: {
    title: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      cardNumber: null,
      update: false,
      showProgress: true,
      dialogPendingActivation: false
    };
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.users.generalInformation,
      openModal: (state) => state.ui.isOpenModalOtp,
      TD_Autenticated: (state) => state.users.tarjetaDigitalAutenticated,
      numberTD: (state) => state.users.numberTD,
      fechaExpiracionTC: (state) => state.users.fechaExpiracion,
      datosCargados: (state) => state.tdr.datosCargados,
      transactions: (state) => state.tdr.transactions,
      pendingActivation: (state) => state.tdr.pendingActivation,
      errorCargaDatos: (state) => state.tdr.errorCargaDeDatos,
      errorValidationTC: (state) => state.users.errorValidationTC,
      tarjeta: (state) => state.tdr.tarjeta,
    }),
    ...mapGetters({
      creditCard: "products/activeCreditCard",
      acceptedCreditCard: "products/acceptedOrDelivered",
      isDigital: "products/isDigital",
      isPhysical: "products/isPhysical",
      conSaldoMora: "tdr/conSaldoMora",
    }),
    external_id() {
      return this.acceptedCreditCard.external_id;
    },
    fullName() {
      return this.generalInfo.first_name !== ""
        ? this.generalInfo.first_name + " " + this.generalInfo.last_name
        : "";
    },
    bloquedCreditCard(){
      return this.tarjeta.bloqueada
    }
  },
  methods: {
    ...mapMutations({
      setOpenModal: "ui/setIsOpenModalOtp",
      setNumberTD: "users/setNumber_td",
      setFechaExpiracion: "users/setFechaExpiracion",
      setCVV: "users/setCVV",
      setTd_Autentication: "users/setTD_Autenticaded",
      setDatosCargados: "tdr/setDatosCargados",
      setErrorCargaDatos: "tdr/setErrorCargaDatos",
      setPendingActivation: "tdr/setPendingActivation"
    }),
    ...mapActions({
      getTarjetaInfo: "tdr/getTarjetaInfo",
    }),
    abrirModal() {
      this.setOpenModal(true);
      this.logauthTD();
    },
    logauthTD() {
      this.setNumberTD("");
      this.setFechaExpiracion("");
      this.setCVV("");
      this.setTd_Autentication(false);
      this.setErrorCargaDatos(false)
      this.setPendingActivation(false)
    },
  },
  beforeDestroy() {
    this.logauthTD();
    this.$store.commit("users/setErrorValidationTC", false);
  },
  created() {
    //
    this.$store.dispatch("products/getQntProducts")
    this.$store.dispatch("products/getPreferredLocation")
    //this.$store.dispatch("tdr/getTarjetaInfo", {externalId: this.external_id})
  },
  watch: {
    'acceptedCreditCard'(newVal, oldVal) {
      if(newVal !== oldVal){
        this.$store.dispatch("tdr/getTarjetaInfo", {
    externalId: newVal.external_id
    });
      }
    },
    'pendingActivation'(val){
      if(val){
      console.log("pendiing activation");
      this.dialogPendingActivation = true
    }
    }
  }
};
</script>

<style scoped>
.tarjeta {
  background: #131313;
  border-radius: 30px;
  box-shadow: 0px 20px 40px -10px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.5s;
}

.tarjeta:hover {
  box-shadow: 0px 40px 80px -20px rgba(0, 0, 0, 0.5);
}

.banner {
  min-height: 200px;
}
.date {
  font-weight: bold; /* Texto en negrita */
  color: black; /* Color negro */
}

.value {
  font-weight: bold; /* Texto en negrita */
  color: rgb(72, 71, 71); /* Color verde */
  font-size: 1.1rem;
  padding-left: 50px;
}

.info-item i {
  margin-right: 10px; /* Ajusta el margen derecho del icono */
}

.m-content {
  padding-top: 0; /* Ajusta el espacio superior */
}

.m-portlet__head-title {
  margin-bottom: 0; /* Elimina el espacio inferior */
}

.card i {
  font-size: 2rem;
  color: grey;
}
.item-icon {
  align-items: center;
}
.d-flex {
  gap: 1rem;
}
.card {
  font-size: medium;
}

.row {
  font-size: medium;
}
span {
  font-size: medium;
}
.info-important {
  background-color: rgba(184, 226, 252, 0.762);
}
.info-icon {
  align-items: center;
}
.flex-column {
  padding: 1rem;
}
.card-info.d-flex {
  gap: 0;
}
.item {
  gap: 0;
}
.foot {
  padding: 0;
}
</style>
